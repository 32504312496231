import { useId } from 'react';
import { IconButton } from '@chakra-ui/button';
import Button from '@components/Button';
import { Icon } from '@chakra-ui/icon';
import { Box, Flex, Heading, Link } from '@chakra-ui/layout';
import { useModal } from '~/contexts/ModalContext';
import InquiryForm, {
  InquiryFormProps,
} from '@components/InquiryForm/InquiryForm';
import { Display } from '@components/InquiryForm/InquiryForm.types';
import { STRING_TO_ICON_CLASS } from '@components/RenderIcon';
import { getColor } from '@utils/getColor';
import {
  MenuItemInquiryForm,
  NavigationItemType,
  Visibility,
} from '../Navigation.types';
import useElementClicked, {
  ElementActions,
  ElementNames,
  ElementTypes,
  isTypeEvent,
} from '@components/Analytics/events/ElementClicked';
import dynamic from 'next/dynamic';
import { itemDisplay } from './Header';
const HeaderJumpLinks = dynamic(
  () => import('@components/Navigation/Header/HeaderJumpLinks')
);
const HeaderWhoAreYouSearchingFor = dynamic(
  () => import('@components/Navigation/Header/HeaderWhoAreYouSearchingFor')
);
export interface SecondaryMenuItemProps {
  item: {
    type: NavigationItemType | null;
    text: string;
    textColor: string;
    textColorRange: string;
    secondText: string;
    secondTextColor: string;
    secondTextColorRange: string;
    icon: string;
    link: {
      enabled: boolean;
      url: string;
    };
    variant?: 'solid' | 'outline' | 'ghost' | null;
    inquiryForm: MenuItemInquiryForm;
    visibility: Visibility;
  };
  metadata: InquiryFormProps['metadata'];
}

const renderMenuItem = ({
  item,
  handleClick,
  metadata,
}: {
  item: SecondaryMenuItemProps['item'];
  handleClick: React.ComponentProps<typeof Button>['handleClick'];
  metadata: InquiryFormProps['metadata'];
}) => {
  switch (item.type) {
    case 'jump_links':
      return (
        <HeaderJumpLinks
          key="jump_links"
          title={item.text}
          {...item}
          titleColor={{
            color: item.textColor,
            range: item.textColorRange,
          }}
        />
      );
    case 'whoAreYouSearchingFor':
      return (
        <HeaderWhoAreYouSearchingFor
          key="who_are_you"
          sectionTitle={item.text}
          buttonVariant={item.variant || 'outline'}
          buttonColor={item.textColor}
          inquiryForm={item.inquiryForm}
          visibility={item.visibility}
          metadata={metadata}
        />
      );
    case 'button':
      return (
        <Button
          // Inquiry buttons should not be rendered as links
          {...(!item.inquiryForm.enabled && {
            as: 'a',
            href: item.link.url,
          })}
          onClick={handleClick}
          colorScheme={item.textColor}
          display={itemDisplay(item.visibility, 'flex')}
          alignItems="center"
          gap={3}
          variant={item.variant || 'solid'}
          elementAction={ElementActions.OPEN_MODAL}
          elementName={ElementNames.GENERIC_BUTTON}
          elementType={isTypeEvent(item.type) ? item.type : ElementTypes.LINK}
          whiteSpace={{ base: 'normal', md: 'nowrap' }}
          size={{ base: 'sm', lg: 'md' }}
          width={{ base: '100%', md: 'auto' }}
        >
          {`${
            item.icon && (
              <Icon
                as={STRING_TO_ICON_CLASS[item.icon]}
                boxSize={15}
                display="block"
              />
            )
          }${item.text}`}
        </Button>
      );
    default:
      // If the item has an icon but no text, render an icon button.
      // It also helps to align the logo to the center of the header.
      if (item.icon && item.link.url && !item.text && !item.secondText) {
        return (
          <IconButton
            as="a"
            href={item.link.url}
            aria-label={item.icon.replace('Md', '')}
            icon={<Icon as={STRING_TO_ICON_CLASS[item.icon]} boxSize="32px" />}
            variant="ghost"
            onClick={handleClick}
            display={itemDisplay(item.visibility)}
          />
        );
      }
      return (
        <Link
          as={item.text ? undefined : Button}
          href={item.link.url}
          onClick={handleClick}
          color="gray.700"
          textDecoration="none"
          display={itemDisplay(item.visibility)}
        >
          {item.icon && (
            <Icon
              as={STRING_TO_ICON_CLASS[item.icon]}
              boxSize="32px"
              display="block"
              aria-label={item.icon.replace('Md', '')}
            />
          )}
          <Flex>
            <Heading
              size="sm"
              as="p"
              color={getColor(item.textColor, item.textColorRange)}
            >
              {item.text}
            </Heading>
            {item.secondText && (
              <Heading
                ml={1}
                size="sm"
                as="p"
                color={getColor(
                  item.secondTextColor,
                  item.secondTextColorRange
                )}
              >
                {item.secondText}
              </Heading>
            )}
          </Flex>
        </Link>
      );
  }
};

const SecondaryMenuItem: React.FC<SecondaryMenuItemProps> = ({
  item,
  metadata,
}) => {
  const modalId = useId();
  const { show: openInquiryForm } = useModal(modalId);
  const elementClicked = useElementClicked();
  const handleClick = item.link.url.includes('tel:')
    ? () =>
        elementClicked({
          element: {
            type: item.icon !== '' ? ElementTypes.ICON : ElementTypes.TELEPHONE,
            action: ElementActions.PHONE_CALL,
            name: ElementNames.PHONE_CALL_BUTTON,
            text: item.text ?? '',
            color: '',
            textColor: `${item.textColor}${item.textColorRange}`,
          },
          destinationUrl: item.link.url.replace('tel:', ''),
        })
    : item.inquiryForm.enabled
    ? () => {
        openInquiryForm();
      }
    : undefined;

  return (
    <>
      {renderMenuItem({ item, handleClick, metadata })}
      {item.inquiryForm.enabled && (
        <Box position="absolute">
          <InquiryForm
            formId={modalId}
            display={Display.FIT_CONTENT_MODAL}
            metadata={metadata}
            {...item.inquiryForm}
            ctaText={item.inquiryForm.ctaText ?? undefined}
            rollUpType={item.inquiryForm.rollUpType ?? undefined}
          />
        </Box>
      )}
    </>
  );
};

export default SecondaryMenuItem;
